import {
  ActivitiesActivitiesBikingIcon,
  ActivitiesActivitiesBoatingIcon,
  ActivitiesActivitiesCampingIcon,
  ActivitiesActivitiesClimbingIcon,
  ActivitiesActivitiesFishingIcon,
  ActivitiesActivitiesHikingIcon,
  ActivitiesActivitiesHorsebackRidingIcon,
  ActivitiesActivitiesKayakingIcon,
  ActivitiesActivitiesOffRoadingIcon,
  ActivitiesActivitiesSkiingIcon,
  ActivitiesActivitiesSleddingIcon,
  ActivitiesActivitiesSurfingIcon,
  ActivitiesActivitiesSwimmingIcon,
  ActivitiesActivitiesWatersportsIcon,
  ActivitiesActivitiesWildlifeWatchingIcon,
  AmenitiesBallFieldIcon,
  AmenitiesBillardsIcon,
  AmenitiesLawnGamesIcon,
  TerrainTerrainBeachIcon,
  TerrainTerrainCountrysideIcon,
  TerrainTerrainDesertIcon,
  TerrainTerrainFarmIcon,
  TerrainTerrainHotSpringIcon,
  TerrainTerrainLakeIcon,
  TerrainTerrainMountainsIcon,
  TerrainTerrainOceanIcon,
  TerrainTerrainParkIcon,
  TerrainTerrainRanchIcon,
  TerrainTerrainSkiIcon,
  TerrainTerrainSwimmingHoleIcon,
  TerrainTerrainVineyardIcon,
  TerrainTerrainWaterfrontIcon,
  TerrainTerrainWoodsIcon,
} from '@outdoorsyco/bonfire';
import { SVGProps } from 'react';

export enum ELocationDetailCategory {
  SCENERY = 'Scenery',
  ACTIVITIES = 'Activities',
}

export enum ELocationDetails {
  BALL_FIELD = 'ball_field',
  BEACH = 'beach',
  BIKING = 'biking',
  BILLIARDS = 'billiards',
  BOATING = 'boating',
  CAMPING = 'camping',
  CLIMBING = 'climbing',
  COUNTRYSIDE = 'countryside',
  COURTS = 'courts',
  DESERT = 'desert',
  FARM = 'farm',
  FISHING = 'fishing',
  HIKING = 'hiking',
  HORSEBACK_RIDING = 'horseback_riding',
  HOT_SPRING = 'hot_spring',
  HOTSPRING = 'hotspring',
  KAYAKING = 'kayaking',
  LAKE = 'lake',
  LAWN_GAMES = 'lawn_games',
  MOUNTAINS = 'mountains',
  OCEAN = 'ocean',
  OFF_ROADING = 'off_roading',
  PARK = 'park',
  RANCH = 'ranch',
  ROCK_CLIMBING = 'rock_climbing',
  SKI = 'ski_in_ski_out',
  SKIING_OR_SNOWBOARDING = 'skiing_or_snowboarding',
  SKIING_SNOWBOARDING = 'skiing_snowboarding',
  SKI_OR_SNOWBOARD = 'ski_or_snowboard',
  SLEDDING = 'sledding',
  SURFING = 'surfing',
  SWIMMING_HOLE = 'swimming_hole',
  SWIMMING = 'swimming',
  VINEYARD = 'vineyard',
  WATERFRONT = 'waterfront',
  WOODS = 'woods',
  WATERSPORTS_CAMPGROUNDS = 'watersports',
  WATERSPORTS = 'paddleboarding_watersports',
  WILDLIFE_WATCHING = 'wildlife_watching',
}
export const LOCATION_DETAILS_ICONS: Record<
  ELocationDetails,
  (props: SVGProps<SVGSVGElement>) => JSX.Element
> = {
  [ELocationDetails.BALL_FIELD]: AmenitiesBallFieldIcon,
  [ELocationDetails.BEACH]: TerrainTerrainBeachIcon,
  [ELocationDetails.BIKING]: ActivitiesActivitiesBikingIcon,
  [ELocationDetails.BILLIARDS]: AmenitiesBillardsIcon,
  [ELocationDetails.BOATING]: ActivitiesActivitiesBoatingIcon,
  [ELocationDetails.CAMPING]: ActivitiesActivitiesCampingIcon,
  [ELocationDetails.CLIMBING]: ActivitiesActivitiesClimbingIcon,
  [ELocationDetails.COUNTRYSIDE]: TerrainTerrainCountrysideIcon,
  [ELocationDetails.COURTS]: TerrainTerrainCountrysideIcon,
  [ELocationDetails.DESERT]: TerrainTerrainDesertIcon,
  [ELocationDetails.FARM]: TerrainTerrainFarmIcon,
  [ELocationDetails.FISHING]: ActivitiesActivitiesFishingIcon,
  [ELocationDetails.HIKING]: ActivitiesActivitiesHikingIcon,
  [ELocationDetails.HORSEBACK_RIDING]: ActivitiesActivitiesHorsebackRidingIcon,
  [ELocationDetails.HOT_SPRING]: TerrainTerrainHotSpringIcon,
  [ELocationDetails.HOTSPRING]: TerrainTerrainHotSpringIcon,
  [ELocationDetails.KAYAKING]: ActivitiesActivitiesKayakingIcon,
  [ELocationDetails.LAKE]: TerrainTerrainLakeIcon,
  [ELocationDetails.LAWN_GAMES]: AmenitiesLawnGamesIcon,
  [ELocationDetails.MOUNTAINS]: TerrainTerrainMountainsIcon,
  [ELocationDetails.OCEAN]: TerrainTerrainOceanIcon,
  [ELocationDetails.OFF_ROADING]: ActivitiesActivitiesOffRoadingIcon,
  [ELocationDetails.PARK]: TerrainTerrainParkIcon,
  [ELocationDetails.RANCH]: TerrainTerrainRanchIcon,
  [ELocationDetails.ROCK_CLIMBING]: ActivitiesActivitiesClimbingIcon,
  [ELocationDetails.SKI]: TerrainTerrainSkiIcon,
  [ELocationDetails.SLEDDING]: ActivitiesActivitiesSleddingIcon,
  [ELocationDetails.SURFING]: ActivitiesActivitiesSurfingIcon,
  [ELocationDetails.SWIMMING_HOLE]: TerrainTerrainSwimmingHoleIcon,
  [ELocationDetails.SWIMMING]: ActivitiesActivitiesSwimmingIcon,
  [ELocationDetails.SKIING_OR_SNOWBOARDING]: ActivitiesActivitiesSkiingIcon,
  [ELocationDetails.SKIING_SNOWBOARDING]: ActivitiesActivitiesSkiingIcon,
  [ELocationDetails.SKI_OR_SNOWBOARD]: ActivitiesActivitiesSkiingIcon,
  [ELocationDetails.VINEYARD]: TerrainTerrainVineyardIcon,
  [ELocationDetails.WATERFRONT]: TerrainTerrainWaterfrontIcon,
  [ELocationDetails.WATERSPORTS]: ActivitiesActivitiesWatersportsIcon,
  [ELocationDetails.WATERSPORTS_CAMPGROUNDS]: ActivitiesActivitiesWatersportsIcon,
  [ELocationDetails.WILDLIFE_WATCHING]: ActivitiesActivitiesWildlifeWatchingIcon,
  [ELocationDetails.WOODS]: TerrainTerrainWoodsIcon,
};
