import React, { SVGProps } from 'react';

import Help from '@/components/switchback/Help/Help';
import IconComponent, { IconType } from '@/components/switchback/Icon/IconComponent';
import Text from '@/components/switchback/Text/Text';
import { useBreakpoint } from '@/hooks/useBreakpoint';

interface IFeatureItemProps {
  icon?: IconType;
  BonfireIcon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  label: string;
  subLabel?: string;
  testId?: string;
  title?: string;
  tooltip?: string;
  unavailable?: boolean;
  notAllowed?: boolean;
  direction?: 'row' | 'column';
}

const FeatureItem: React.FC<IFeatureItemProps> = ({
  icon,
  BonfireIcon,
  label,
  subLabel,
  testId = 'feature-card',
  tooltip,
  unavailable,
  notAllowed,
  direction = 'row',
}) => {
  const { isMobile } = useBreakpoint();

  return (
    <>
      <div
        className={`flex items-center text-base font-normal text-primary-base semiHighlight ${
          direction === 'row' ? 'flex-row' : 'flex-col gap-4'
        }`}
        data-testid={testId}>
        {!!icon && (
          <span
            className={`mr-3 ${direction === 'row' ? 'md:mr-4' : 'md:mr-0'} ${unavailable ? 'opacity-50' : ''}`}>
            <IconComponent
              name={icon}
              width={isMobile ? 18 : 24}
              height={isMobile ? 18 : 24}
              className={unavailable ? 'text-secondary-base' : ''}
              aria-label={label}
              aria-hidden={!label}
            />
          </span>
        )}
        {!!BonfireIcon && (
          <span
            className={`mr-3 ${direction === 'row' ? 'md:mr-4' : 'md:mr-0'} ${unavailable ? 'opacity-50' : ''}`}>
            {(unavailable || notAllowed) && (
              <div
                className="rotate-45 origin-left"
                style={{
                  // hypotenuse width -> 100% * sqrt(2) = 141.421%
                  width: '141.421%',
                }}>
                <div className="border-b border-secondary-base" />
                <div className="border-b border-white" />
              </div>
            )}

            <BonfireIcon
              width={isMobile ? 18 : 24}
              height={isMobile ? 18 : 24}
              className={unavailable ? 'text-secondary-base' : ''}
              aria-label={label}
              aria-hidden={!label}
            />
          </span>
        )}
        <div>
          <div className="flex items-center">
            <Text
              type="inline"
              className={`flex items-center text-base font-normal text-primary-base semiHighlight ${
                unavailable ? 'line-through opacity-50' : ''
              }`}>
              {label}
            </Text>
            {tooltip && !isMobile && (
              <Help className="ml-1.5" label={label || ''} content={tooltip} />
            )}
          </div>
          {subLabel && (
            <Text
              type="block"
              className="flex items-center text-base font-normal text-primary-base semiHighlight">
              ({subLabel})
            </Text>
          )}
        </div>
      </div>
    </>
  );
};

export default FeatureItem;
