import {
  EHeadingStyleVariant,
  GeneralEyeNormalIcon,
  GeneralPlayIcon,
  Heading,
} from '@outdoorsyco/bonfire';
import NextLink from 'next/link'; // TODO: use switchback link here but for now is OK
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ISimpleImage } from '@/components/route/campground/campgroundTypes';
import Loading from '@/components/switchback/Loading/Loading';
import ResponsiveImage, { ImageSizes } from '@/components/utility/ResponsiveImage/ResponsiveImage';
import { PHOTOS } from '@/constants/urls';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useRouter } from '@/hooks/useRouter';
import { EImageTags, IImage } from '@/services/types/search/rentals/id';

import css from './PhotoGallery.module.css';

type ImageOrientation = 'portrait' | 'landscape' | 'square' | 'unknown';

type TImage = IImage | ISimpleImage;

export function getImageOrientation(image: TImage): ImageOrientation {
  if (!image.width || !image.height) {
    return 'unknown';
  }

  if (image.width === image.height) {
    return 'square';
  }

  return image.width < image.height ? 'portrait' : 'landscape';
}

export function getShouldSplitEvenly(images: TImage[]): boolean {
  if (images.length !== 2) {
    return false;
  }

  // @ts-expect-error fixable: unchecked index access
  const imageAOrientation = getImageOrientation(images[0]);
  // @ts-expect-error fixable: unchecked index access
  const imageBOrientation = getImageOrientation(images[1]);

  return imageAOrientation === imageBOrientation;
}

export function getFeaturedImages(images: IImage[], offset = 0): IImage[] {
  const onlyFloorplans = images.every(item => item.tags === EImageTags.FLOORPLAN);

  if (onlyFloorplans) {
    return images.slice(offset, 4);
  }

  const sortedImages = images
    .filter(item => !item.video || item.odnMedia?.isVideo)
    .filter(item => item.tags !== EImageTags.FLOORPLAN)
    .slice(offset)
    .sort((a, b) => a.position - b.position)
    .sort((a, b) => Number(b.primary) - Number(a.primary))
    .slice(0, 4)
    .sort(
      (a, b) =>
        Number(getImageOrientation(a) === 'portrait') -
        Number(getImageOrientation(b) === 'portrait'),
    );

  return sortedImages;
}

type ResponsiveImageType = '1' | '1/2' | '3/4' | '1/4' | '1/4/2';

const mapResponsiveImageTypeToProps: {
  [key in ResponsiveImageType]: {
    sizes: string;
    sourceSizes: ImageSizes;
  };
} = {
  '1': {
    sizes: '100vw, (min-width: 1440px) 1440px',
    sourceSizes: ['landscape768', 'landscape1440', 'landscape2880'],
  },
  '3/4': {
    sizes: '75vw, (min-width: 1440px) 1080px',
    sourceSizes: ['landscape768', 'landscape1080', 'landscape2160'],
  },
  '1/2': {
    sizes: '50vw, (min-width: 1440px) 720px',
    sourceSizes: ['square320', 'square720', 'square1440'],
  },
  '1/4': {
    sizes: '25vw, (min-width: 1440px) 360px',
    sourceSizes: ['portrait192', 'portrait375', 'portrait768'],
  },
  '1/4/2': {
    sizes: '25vw, (min-height: 1440px) 360px',
    sourceSizes: ['square320', 'square720'],
  },
};

export function getResponsiveImageType({
  numImages,
  index,
  shouldSplitEvenly = false,
}: {
  numImages: number;
  index: number;
  shouldSplitEvenly?: boolean;
}): ResponsiveImageType {
  switch (Math.trunc(numImages)) {
    case 2: {
      if (shouldSplitEvenly) {
        return '1/2';
      }

      return index === 0 ? '3/4' : '1/4';
    }
    case 3: {
      return index === 0 ? '3/4' : '1/4/2';
    }
    case 4: {
      if (index === 0) return '1/2';
      if (index === 1 || index === 2) return '1/4/2';
      return '1/4';
    }
  }

  return '1';
}

const PhotoGalleryLoadingPlaceholder: React.FC = () => {
  return (
    <>
      {[...Array(3)].map((_, index) => (
        <div className={`bg-gray-200 relative ${css.item}`} key={index}>
          <Loading className="absolute inset-0 flex items-center justify-center" />
        </div>
      ))}
    </>
  );
};

interface IProps {
  images: TImage[];
  isSimple?: boolean;
  loading?: boolean;
  onClick?: (index: number) => void;
  className?: string;
}

const PhotoGallery: React.FC<IProps> = ({ images, isSimple, loading, onClick, className }) => {
  const router = useRouter();
  const intl = useIntl();

  const linkLabel = intl.formatMessage({ defaultMessage: 'Explore the vehicle', id: 'dRCqY6' });
  const imagesHref = `${router.route}${PHOTOS}`;
  const { isMobile } = useBreakpoint();

  const featuredImages = useMemo(() => {
    return isSimple ? images : getFeaturedImages(images as IImage[]);
  }, [isSimple, images]);

  const shouldSplitEvenly = useMemo(() => getShouldSplitEvenly(featuredImages), [featuredImages]);

  const getResponsiveImageProps = (index: number) =>
    mapResponsiveImageTypeToProps[
      getResponsiveImageType({
        numImages: featuredImages.length,
        index,
        shouldSplitEvenly,
      })
    ];

  const renderResponsiveImage = (item: TImage, index: number) => {
    return (
      <>
        {item.odnMedia ? (
          <div className="relative w-full h-full">
            <ResponsiveImage
              className="object-cover w-full h-full transition-transform duration-300 ease-in-out transform"
              src={item.url}
              alt={item.alt}
              cropMode="fit"
              priority={index < 4}
              withFetchPriority={index < 4 ? 'high' : undefined}
              {...getResponsiveImageProps(index)}
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="flex flex-col items-center justify-center text-center text-white gap-2">
                {item.odnMedia.is360Tour ? (
                  <GeneralEyeNormalIcon className="text-2xl" />
                ) : (
                  <div className="p-2 bg-white rounded-full">
                    <GeneralPlayIcon className="text-2xl text-black" />
                  </div>
                )}

                {item.odnMedia.is360Tour && (
                  <Heading variant={EHeadingStyleVariant.H5}>
                    <FormattedMessage defaultMessage="Explore 360 tour" id="3r33Bh" />
                  </Heading>
                )}
              </div>
            </div>
          </div>
        ) : (
          <ResponsiveImage
            className="object-cover w-full h-full transition-transform duration-300 ease-in-out transform"
            src={item.url}
            alt={item.alt}
            cropMode="fit"
            priority={index < 4}
            withFetchPriority={index < 4 ? 'high' : undefined}
            {...getResponsiveImageProps(index)}
          />
        )}
      </>
    );
  };
  return (
    <div
      className={`grid gap-1 h-full ${css.container} ${className}`}
      data-children={String(featuredImages.length)}
      data-split-even={String(shouldSplitEvenly)}
      data-loading={loading}>
      {!loading ? (
        featuredImages.map((item, index) => {
          const routerQuery = isMobile ? { ...router.query } : { ...router.query, image: item.id };
          return (
            <div
              onClick={() => onClick && onClick(index)}
              className={`overflow-hidden bg-gray-200 ${css.item}`}
              key={index}>
              {isSimple ? (
                renderResponsiveImage(item, index)
              ) : (
                <NextLink
                  href={{ pathname: imagesHref, query: routerQuery }}
                  passHref
                  legacyBehavior>
                  <a className="w-full h-full">
                    <span className="sr-only">{linkLabel}</span>
                    {renderResponsiveImage(item, index)}
                  </a>
                </NextLink>
              )}
            </div>
          );
        })
      ) : (
        <PhotoGalleryLoadingPlaceholder />
      )}
    </div>
  );
};

export default PhotoGallery;
